import {mapMutations, mapActions, mapGetters} from "vuex";

//sections


export default {
  name: "manuals",
  components: {},
  data() {
    return {
      crumbs: [
        {src: 'home', title: this.$t('home.title'), slug: '/'},
        {src: '/manual', title: this.$t('manuals.title'), slug: 'manual'}
      ],
    }
  },
  created() {},
  computed: {
    ...mapGetters({
      manuals: 'manuals/manuals',
      pageContent: 'setting/pageContent'
    })
  },

  methods: {
    ...mapActions({
      getManuals: 'manuals/GET_MANUALS',
      getPageContent: 'setting/GET_PAGE_CONTENT'
    }),
    ...mapMutations({})
  }
}
